<template>
  <el-dialog
    :modal="false"
    :title="title + ' ' + allSelectList.dealer_name"
    :visible.sync="showDialog"
    top="5vh"
    fullscreen
    :close-on-click-modal="false"
    destroy-on-close
  >
    <!--    <h3>{{form.dealer_name}}</h3>-->
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        :disabled="view"
        label-position="right"
        label-width="90px"
      >
        <el-form-item label="购货方:" prop="cust_name">
          <client-search
            ref="clientSearch"
            :popper-class="'selectDC'"
            style="width: 192.6px"
            @select-val-alldata="clientSelect"
          ></client-search>
        </el-form-item>
        <el-form-item label="联系人:" prop="boss">
          <el-input v-model="form.boss" disabled></el-input>
        </el-form-item>
        <el-form-item
          v-if="allSelectList.ckzx_falg == 1"
          label="业务员:"
          prop="staff_id"
        >
          <!-- <el-input v-model="form.staff_id"></el-input> -->
          <el-select
            v-model="form.staff_id"
            clearable
            style="width: 200px"
            placeholder=""
          >
            <el-option
              v-for="(i, idx) in staffList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <div></div>
        <el-form-item label="电话:" prop="mobile">
          <el-input v-model="form.mobile" disabled></el-input>
        </el-form-item>

        <el-form-item label="地址:" prop="address">
          <el-input
            v-model="form.address"
            disabled
            style="width: 360px"
          ></el-input>
        </el-form-item>
        <div></div>

        <el-form-item label="仓库:" prop="depot_name">
          <el-select
            v-model="form.depot_id"
            clearable
            placeholder="请选择"
            style="width: 192.6px"
          >
            <el-option
              v-for="(i, idx) in depotSelect"
              :key="idx"
              :value="i.id"
              :label="i.depot_name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="!view" label="商品条码:" prop="">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="快捷输入:">
          <el-autocomplete
            v-model="remark"
            :disabled="tableEdit"
            class="inline-input"
            value-key="goods_name"
            :fetch-suggestions="handlerremark"
            placeholder="请输入内容"
            :trigger-on-focus="false"
            @select="selectremark"
          ></el-autocomplete>
        </el-form-item>
        <el-table
          stripe
          :data="list"
          style="margin-bottom: 15px"
          :summary-method="getSummaries"
          show-summary
        >
          <!-- 序号 -->
          <el-table-column
            align="center"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.label == '商品名称' ? '240' : item.width"
            align="center"
          >
            <template #default="{ $index, row }">
              <div v-if="item.label == '类型'">
                <el-select
                  v-model="row.goods_type"
                  placeholder="选择类型"
                  style="width: 100%"
                  @change="utilsF(), handleTypeChange($event, row),moneyN(row,$index)"
                >
                  <el-option
                    v-for="item_ in row.arr_goods_type"
                    :key="item_.id"
                    :label="item_.name"
                    :value="item_.id"
                  ></el-option>
                </el-select>
              </div>
              <div v-else-if="item.label == '商品名称'">
                <goods-search
                  ref="goodsSearch"
                  :disab="tableEdit"
                  :cust-id="cust_id"
                  :goods-id="row.goods_id"
                  :kouwei="row.child"
                  :rowf="row"
                  :f-key="row.goods_name"
                  :depot-id="form.depot_id"
                  @change-kouwei="changeKouwei($event, row)"
                  @select-goods-all="handleSelect($index, row, $event)"
                  @add-rows="addRows"
                ></goods-search>
                <!-- <el-autocomplete
                  v-model="row.goods_name"
                  class="inline-input"
                  popper-class="selectDC"
                  :fetch-suggestions="querySearch2"
                  value-key="goods_name"
                  placeholder="请输入商品名称、简拼"
                  @select="handleSelect($index, row, $event)"
                  @input="addRow($index, row, $event)"
                >
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                    @click="iconClick($index, row)"
                  ></i>
                  <template slot-scope="{ item_ }">
                    <div class="wrap" style="display: flex">
                      <div
                        class="name"
                        style="width: 200px; margin-right: 120px"
                      >
                        {{ item_.goods_name }}
                      </div>
                      <span class="addr">{{ item_.specs }}</span>
                    </div>
                  </template>
                </el-autocomplete> -->
              </div>
              <div v-else-if="item.label == '规格'">
                {{ row.specs || '' }}
              </div>
              <div v-else-if="item.label == '条码'">
                {{ currUnitCode(row) }}
              </div>
              <div v-else-if="item.label == '单位'">
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 70px"
                  @change="unitChange($event, row), utilsF()"
                >
                  <el-option
                    v-for="item_ in row.arr_unit"
                    :key="item_.id"
                    :label="item_.unit_name"
                    :value="item_.id"
                  ></el-option>
                </el-select>
              </div>
              <div v-else-if="item.label == '单价'">
                <el-input
                  v-model="row.goods_price"
                  style="width: 80px"
                  :disabled="isEditPrice == 0"
                  onkeyup="value=value.replace(/[^\-?\d.]/g,'')"
                  :class="'danjia' + $index"
                  @input="moneyN(row, $index), utilsF()"
                  @keyup.native.stop.prevent="danjiaD($event, $index, 'danjia')"
                />
              </div>
              <div v-else-if="item.label == '数量'">
                <el-input
                  v-model="row.quantity"
                  style="width: 80px"
                  onkeyup="value=value.replace(/[^\-?\d.]/g,'')"
                  :class="'shuliang' + $index"
                  @input="moneyN(row, $index), utilsF()"
                  @focus="inputfocus(row)"
                  @keyup.native.stop.prevent="
                    danjiaD($event, $index, 'shuliang')
                  "
                />
              </div>
              <div v-else-if="item.label == '金额'">
                <!-- {{ Number(row.quantity * row.goods_price).toFixed(2) }} -->
                <el-input
                  v-model.number="row.goods_money"
                  style="width: 80px"
                  @input="summaryInput($event, row), utilsF()"
                />
              </div>
              <div v-else-if="item.label == '备注'">
                <el-input v-model="row.info" />
              </div>
              <div v-else-if="item.label == '生产日期'">
                <!-- goods_production_date -->
                <el-date-picker
                  v-model="row.goods_production_date"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker>
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right">
            <!--            width="160"-->
            <template #default="{ $index, row }">
              <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
              <el-button
                type="text"
                @click.native.prevent="copyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="list.length != 0">
          <el-form-item label="备注:" prop="remark" style="width: 100%">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item label="结算方式:" prop="account_type_text">
            <el-select
              v-model="form.account_type"
              clearable
              style="width: 192.6px"
              placeholder="结算方式"
            >
              <el-option
                v-for="(i, idx) in jsfs"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="派送收款:">
            <el-input v-model.trim="form.deliver_collect" disabled></el-input>
          </el-form-item>-->
          <el-form-item label="订单金额:" prop="total_amount">
            <el-input v-model="form.total_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="使用预存款:" prop="deposit_amount">
            <el-input v-model="form.deposit_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="还货金额:" prop="return_amount">
            <el-input v-model="form.return_amount" disabled></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="销售应收:" prop="sale_amount">
            <el-input v-model="form.sale_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="优惠" prop="discount_amount">
            <el-input
              v-model.trim="form.discount_amount"
              @input="yhInputF"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠后:" prop="discounted_amount">
            <el-input
              v-model="form.discounted_amount"
              type="number"
              @input="yhhInputF"
            ></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="退货退款" prop="refund_amount">
            <el-input v-model.trim="form.refund_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="退货金额" prop="refund_goods_amount">
            <el-input
              v-model.trim="form.refund_goods_amount"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="退货方式" prop="refund_type" style="width: 40%">
            <el-select
              v-model="form.refund_type"
              placeholder="退货方式"
              style="width: 186px"
              @change="utilsF"
            >
              <el-option
                v-for="item_ in allSelectList.arr_refund_type"
                :key="item_.id"
                :label="item_.name"
                :value="item_.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="销售金额" prop="sale_amount">
            <el-input v-model.trim="form.sale_amount" disabled></el-input>
          </el-form-item>

          <div></div>
          <el-form-item v-if="form.is_fun" label="费用抵扣" prop="thtk">
            <el-input
              v-model.trim="form.support_amount"
              @input="yshandler"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="form.is_fun">
            <el-select
              v-model="form.fun_ids"
              placeholder="抵扣类型"
              style="width: 186px"
              multiple
              collapse-tags
              @change="handlerfydk"
            >
              <el-option
                v-for="item_ in funselect"
                :key="item_.id"
                :label="item_.funds_type + `(合计${item_.left_amount})`"
                :value="item_.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应收款:" prop="receiv_money">
            <el-input v-model="form.receiv_money" disabled></el-input>
          </el-form-item>

          <!-- <el-form-item label="实收款:" prop="pay_amount">
            <el-input v-model="form.pay_amount"></el-input>
          </el-form-item> -->
          <el-form-item
            v-for="(item1, index) in pay_info"
            :key="index"
            label="实收款:"
          >
            <div style="display: flex">
              <el-input
                v-model.trim="item1.money"
                type="number"
                style="width: 90px; margin-right: 20px"
              ></el-input>
              <el-select v-model="item1.pay_type" style="width: 90px">
                <el-option
                  v-for="item_1 in allSelectList.arr_pay_type"
                  :key="item_1.id"
                  :label="item_1.name"
                  :value="item_1.id"
                ></el-option>
              </el-select>
            </div>
            <i
              v-if="index == 0"
              class="el-icon-circle-plus addbtn"
              @click="addInput"
            ></i>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保 存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
    <!-- 表格输入框弹窗 -->
    <!-- <table-input-window
      ref="tableInputWindow"
      @add-rows="addRows"
    ></table-input-window> -->
    <!-- <input-window
      ref="inputWindow"
      @change-client="changeClient"
    ></input-window> -->
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { depotList, saveDepotSale } from '@/api/depotManagement'
  import { getInputList } from '@/api/goodsSearchInput'
  import { getAllSelect } from '@/api/advanceOrder'
  // import TableInputWindow from '@/baseComponents/goodsChooseDialog'
  // import InputWindow from '@/views/project/sale/bills/advanceOrder/components/addOrder/components/inputWindow.vue'
  import { yhhInput, utils, yhInput } from '@/utils/AOform.js'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { getStaffList } from '@/api/setPrice'
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: { ClientSearch, GoodsSearch },
    data() {
      return {
        title: '添加仓库直销单',
        remark: '',
        funds_zong: '',
        funselect: [],
        showDialog: false,
        staffList: [],
        loading: false,
        view: false,
        cust_id: '',
        jsfs: [
          {
            id: 1,
            name: '货到付款',
          },
          {
            id: 2,
            name: '账期结算',
          },
        ],
        allSelectList: {},
        pay_info: [{ pay_type: '', money: '0' }],
        depotSelect: [],
        list: [],
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '数量',
          '可用库存',
          '实际库存',
          '金额',
          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            prop: 'goods_type_text',
            width: '130px',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '220px',
          },
          {
            order: 2.1,
            label: '规格',
            prop: 'specs',
            width: '80px',
          },
          {
            order: 2.2,
            label: '条码',
            width: '140px',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 3,
            label: '单位',
            prop: 'unit_name',
            width: '90px',
          },
          {
            order: 4,
            label: '单价',
            prop: 'goods_price',
            width: '100px',
          },
          {
            order: 5,
            label: '数量',
            prop: 'quantity',
            width: '100px',
          },
          {
            order: 5.1,
            label: '可用库存',
            prop: 'depot_stock_num_text',
            width: '100px',
          },
          {
            order: 5.2,
            label: '实际库存',
            prop: 'balance_count_read',
            width: '100px',
          },
          {
            order: 6,
            label: '金额',
            prop: 'goods_money',
            width: '100px',
          },
          {
            order: 6.1,
            label: '生产日期',
            prop: 'goods_production_date',
            width: '180px',
          },
          {
            order: 7,
            label: '备注',
            prop: 'info',
            width: '200px',
          },
        ],
        form: {
          cust_name: '',
          staff_id: '',
          boss: '',
          mobile: '',
          address: '',
          depot_id: '',
          discounted_amount: 0,
          discount_amount: 0,
          support_amount: 0,
          fun_ids: [],
          refund_type: 1,
          account_type: '',
          remark: '',
          receiv_money: 0,
          refund_amount: 0,
          refund_goods_amount: 0,
          total_amount: 0,
          sale_amount: 0,
        },
      }
    },
    computed: {
      isEditPrice() {
        return localStorage.getItem('isEditPrice')
      },
      currUnitCode() {
        return (row) => {
          if (row.arr_unit && row.arr_unit.length > 0) {
            let a = row.arr_unit.filter(
              (i) => (i.unit_id || i.id) == row.unit_id
            )[0].unit_code
            return a
          } else {
            return ''
          }
        }
      },
      tableEdit() {
        if (this.form.depot_id && this.cust_id) {
          return false
        } else {
          return true
        }
      },
      // 是否可选择仓库
      isDepot() {
        if (
          this.list.length == 0 ||
          (this.list.length == 1 && this.list[0].goods_name == '')
        ) {
          return false
        } else {
          return true
        }
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.addRow(-1)
          depotList().then((res) => {
            console.log(res)
            // 获取默认值
            let d = res.data.filter((i) => i.is_default == 1)[0]
            console.log('ddd', d)
            this.form.depot_id = d.id
            this.depotSelect = res.data
          })
          this.getAllSelectList()
        } else {
          this.clearForm()
        }
      },
      'form.receiv_money'(v) {
        this.pay_info[0].money = JSON.parse(JSON.stringify(v))
      },
      'form.support_amount'(val) {
        // utils(this.list, this, 3)
        this.yshandler()
      },
    },
    created() {},
    mounted() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
    },
    methods: {
      handlerfydk(v) {
        let fydk = 0
        console.log(v)
        v.map((list) => {
          fydk += Number(
            this.funselect.filter((item) => item.id == list)[0].left_amount
          )
        })
        this.form.support_amount = Number(fydk)
      },
      // 单价输入框按
      danjiaD(e, index, name) {
        let n = 0
        let l = this.list.length
        if (e.code == 'ArrowUp') {
          // 按键 上
          while (n <= l) {
            if (
              document.getElementsByClassName(name + (index - 1 - n)).length >
                1 &&
              document.getElementsByClassName(name + (index - 1 - n))[0]
                .children[0]
            ) {
              document
                .getElementsByClassName(name + (index - 1 - n))[0]
                .children[0].focus()
              n += l + 1
            } else {
              n++
            }
          }
        } else if (e.code == 'ArrowDown') {
          // 下
          while (n <= l) {
            if (
              document.getElementsByClassName(name + (index + 1 + n)).length >
                1 &&
              document.getElementsByClassName(name + (index + 1 + n))[0]
                .children[0]
            ) {
              document
                .getElementsByClassName(name + (index + 1 + n))[0]
                .children[0].focus()
              n += l + 1
            } else {
              n++
            }
          }
          // this.$refs['danjia' + (index + 1)]?.focus()
        }
      },
      // 金额返算
      summaryInput(val, row) {
        console.log(val)
        row.goods_price = (Number(val) / row.quantity).toFixed(2)
      },
      utilsF() {
        utils(this.list, this)
      },
      yhhInputF(val) {
        yhhInput(val, this)
      },
      yhInputF(val) {
        yhInput(val, this)
      },
      yshandler(val) {
        this.form.receiv_money =
          Number(this.form.total_amount) -
          Number(this.form.discounted_amount) -
          Number(this.form.refund_amount)
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        console.log(columns, data)
        const sums = []
        columns.forEach((item) => {
          sums.push('')
        })
        let nums = 0
        let shu = 0

        sums[0] = '合计'
        data.forEach((item) => {
          if (item.goods_type == 1) {
            let num = Number(
              (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
            )
            nums += num
          } else if (item.goods_type == 6) {
            let num = Number(
              (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
            )
            nums -= num
            // this.form.total_amount = nums
          }
        })
        data.forEach((item) => {
          if (item.goods_type == 8) {
            let num = Number(
              (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
            )
            nums -= num
          }

          if (!isNaN(item.quantity)) {
            shu += Number(item.quantity)
          }
        })
        sums[7] = shu.toFixed(2)
        // sums[10] = nums.toFixed(2) + '元'
        sums[10] = this.form.total_amount
        // this.form.total_amount = nums.toFixed(2)
        return sums
      },
      // 表格合计
      // getSummaries(param) {
      //   // console.log(1111)
      //   const { columns, data } = param
      //   const sums = []
      //   columns.forEach((column, index) => {
      //     if (index === 0) {
      //       sums[index] = '总价'
      //       return
      //     }
      //     // if (index === 12) {
      //     //   sums[
      //     //     index
      //     //   ] = `<el-button type="text" @click.native.prevent="addRow(row)"> 添加 </el-button>`
      //     //   return
      //     // }
      //     const values = data.map((item) => Number(item[column.property]))
      //     if (!values.every((value) => isNaN(value))) {
      //       sums[index] = values.reduce((prev, curr) => {
      //         const value = Number(curr)
      //         if (!isNaN(value)) {
      //           return prev + curr
      //         } else {
      //           return prev
      //         }
      //       }, 0)
      //       if (index == 7) {
      //       } else if (index == 6) {
      //         sums[index] += ' 元'
      //       } else {
      //         sums[index] = ''
      //       }
      //     } else {
      //       sums[index] = ' '
      //     }
      //   })

      //   return sums
      // },
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log('所有下拉列表2')
          console.log(data)
          this.allSelectList = data
          this.allSelectList.arr_pay_type.forEach((i) => {
            if (i.name == '现金') {
              this.pay_info[0].pay_type = i.id
            }
          })
        }
      },
      // 商品搜索下拉
      async fetchData(ky = '') {
        // if (!this.form.depot_id) {
        // this.$message.error('请选择出货仓库')
        // return false
        // } else {
        let { data, code, msg } = await getInputList({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
          // depot_id: this.form.depot_id,
        })
        console.log(data)
        if (code == 200) {
          return data
        } else {
          this.$message.error(msg)
        }
        // }
      },
      selectremark(item) {
        console.log(item)
        this.list.unshift(item)
        this.remark = ''
      },
      async handlerremark(queryString, cb) {
        const { data } = await getInputList({
          keyword: queryString,
          depot_id: this.form.depot_id,
          custom_id: this.form.id,
        })
        if (data.length > 0) {
          let goodsdata = data.map((item, index) => {
            try {
              item.arr_unit.forEach((unit) => {
                if (unit.is_default == 2) {
                  item.unit_id = unit.id
                  item.goods_price = unit.sell_price
                  item.unit_name = unit.unit_name
                }
              })
              item.goods_type = 1
              item.type = '销常'
            } catch (err) {}
            return item
          })
          cb(goodsdata)
        } else {
          cb([])
        }
      },
      handleSelect(index, row, $event) {
        console.log(index, row, $event)
        if ($event.id) {
          let goods_price = $event.arr_unit.filter(
            (item) => item.unit_id == $event.unit_id
          )
          if (goods_price) {
            row.goods_price =
              (goods_price.sell_price == 0
                ? goods_price.purc_price
                : goods_price.sell_price) == 0
                ? goods_price.cost_price
                : goods_price.sell_price == 0
                ? goods_price.purc_price
                : goods_price.sell_price
          }
          console.log('row.', goods_price.sell_price)
          Object.assign(row, $event, { goods_type: 1 })

          console.log(this.list)
          this.addRow(index, row)
        }
        // row.arr_unit.forEach((unit) => {
        //   if (unit.is_default == 2) {
        //     row.unit_id = unit.id
        //     row.goods_price = unit.sell_price
        //   }
        // })
        // if (row.unit_id == '') {
        //   row.arr_unit.forEach((unit) => {
        //     if (unit.unit_type == 1) {
        //       row.unit_id = unit.id
        //       row.goods_price = unit.sell_price
        //     }
        //   })
        // }
      },
      // 添加行
      addRow(index, row, $event) {
        if (this.list.length == index + 1) {
          this.list.push({
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            balance_count_read: '',
            depot_stock_num: 0,
            depot_stock_num_text: '',
            edit: true,
            goods_child_id: null,
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
          })
        }
      },
      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.list.splice(index + 1, 0, rowa)
        // this.list.push(rowa)
        console.log(rowa)
        this.utilsF()
      },
      deleteRow(index, row) {
        if (index == this.list.length - 1) {
          // alert('不能删除最后一行')
          this.$message.error('不能删除最后一行')
          return
        }
        this.list.splice(index, 1)
        this.utilsF()
      },
      addRows(val) {
        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            this.list.splice(this.list.length - 1, 0, item)
          })
          this.$message.success('添加成功')
          this.utilsF()
        }
      },
      // 表格内商品名称输入框搜索
      querySearch2(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            cb(val)
          })
        }
      },
      addInput() {
        this.pay_info.push({ pay_type: '', money: '0' })
      },
      close() {
        this.showDialog = false
      },
      clientSelect(val) {
        console.log(val, '选中分会')
        if (val) {
          this.form.is_fun = val.is_fun
          this.cust_id = val.id
          this.form.boss = val.boss
          this.form.mobile = val.mobile
          this.form.address = val.address
          this.form.account_type = Number(val.pay_way)
        }
        postAction('/saleAdmin/order-pre/funds-option', {
          cust_id: val.id,
        }).then((res) => {
          this.funselect = res.data.bill_list
          this.funds_zong = res.data.funds_zong
        })
      },
      handleTypeChange(v, row) {
        console.log('v', v, row)
        let unitId = row.arr_unit.filter((i) => i.id == row.unit_id)
        if (v == 6 || v == 10 || v == 11 || v == 13 || v == 14) {
          if (unitId.length > 0) {
            row.goods_price = unitId[0].return_price
          }
        } else {
          row.goods_price = unitId[0].sell_price
        }
      },
      inputfocus(row) {
        console.log('input focus')
        if (row.pre_quantity == 0) {
          row.pre_quantity = ''
        }
        if (row.quantity == 0) {
          row.quantity = ''
        }
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.$refs.clientSearch.resetForm()
        this.pay_info = [{ pay_type: '', money: '0' }]
        this.cust_id = ''
        this.form.depot_id = ''
        this.form.staff_id = ''
        this.list = [
          {
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            balance_count_read: '',
            depot_stock_num: 0,
            depot_stock_num_text: '',
            edit: true,
            goods_child_id: null,
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
          },
        ]
      },
      goodsSelect(val, row) {
        console.log(val)
      },
      // 计算金额数
      moneyN(item, index) {
        let num = 0
        // if (Number(item.quantity) && Number(item.goods_price)) {
        //   num = Number(item.quantity) * Number(item.goods_price)
        // }
        num = Number(item.quantity) * Number(item.goods_price)
        this.list[index].goods_money = num.toFixed(2)
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        row.goods_price = price
        row.goods_money = (Number(price) * Number(row.quantity)).toFixed(2)
      },
      iconClick(index) {
        console.log(index)
        if (!this.form.depot_id) {
          this.$message.error('请选择出货仓库')
          return false
        } else {
          this.$refs['tableInputWindow'].showDialog = true
          this.$refs['tableInputWindow'].depotId = this.form.depot_id
        }
        // this.tableInputWindow = true
      },
      changeClient(row) {},
      handleSave() {
        let list = JSON.parse(JSON.stringify(this.list))
        let goods_info = []
        list.forEach((item) => {
          if (item.goods_id) {
            goods_info.push({
              goods_id: item.goods_id,
              unit_id: item.unit_id,
              goods_child_id: item.goods_child_id,
              quantity: item.quantity,
              goods_price: item.goods_price,
              goods_money: item.goods_money,
              goods_production_date: item.goods_production_date,
              info: item.info,
              activity_id: item.activity_id,
              activity_count: item.activity_count,
              goods_type: item.goods_type,
            })
          }
        })
        console.log(goods_info)
        let pay_info_ = JSON.parse(JSON.stringify(this.pay_info))
        let pay_type = []
        pay_info_.forEach((item) => {
          if (item.money) {
            pay_type.push(item)
          }
        })
        let order_info = {
          depot_id: this.form.depot_id,
          discounted_amount: this.form.discounted_amount,
          refund_type: this.form.refund_type,
          account_type: this.form.account_type,
          remark: this.form.remark,
          fun_ids: this.form.fun_ids.join(','),
          support_amount: this.form.support_amount,
        }
        let end = {
          cust_id: this.cust_id,
          staff_id: this.form.staff_id,
          order_info: JSON.stringify(order_info),
          pay_info: JSON.stringify(pay_type),
          goods_info: JSON.stringify(goods_info),
        }
        console.log(end)
        saveDepotSale(end).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
    },
  }
</script>
<style lang="scss" scoped>
  .addbtn {
    position: absolute;
    top: 0;
    right: -50px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
</style>
