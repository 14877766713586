<template>
  <!-- NAME[epic=库管] 仓库直销 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="depot_id">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="请选择仓库"
        >
          <el-option
            v-for="(i, idx) in ckList"
            :key="idx"
            :value="i.id"
            :label="i.depot_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="time">
        <el-tooltip
          class="item"
          effect="dark"
          content="默认显示6个月内的订单，可点击时间框修改起止时间，查询其他时段订单"
          placement="top-start"
        >
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 240px"
          ></el-date-picker>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'select-idx selectDC'"
          @select-val-alldata="setClientKeyword"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入单号"
          style="width: 150px"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="pay_status">
        <el-select
          v-model="form.pay_status"
          clearable
          style="width: 140px"
          placeholder="是否结清"
        >
          <el-option
            v-for="(i, idx) in jieqingList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="pay_status">
        <el-select
          v-model="form.bill_status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in zhuangtaiList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleAdd">添加仓库直销单</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="handleAddDuijiang">
          添加兑奖单
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
                @change="filterChcekboxChange"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '销售单号'"
            class="underline"
            @click="handleOrderDetail(row)"
          >
            {{ row[item.prop] }}

            <div class="btnwrap">
              <el-tag v-for="tag in row.sale_type_txt" :key="tag">
                {{ tag }}
              </el-tag>
              <!-- <el-tag v-if="Number(row.discount_amount) != 0">优惠</el-tag> -->
            </div>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="140px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <el-button
            v-if="row.bill_status == 1"
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="row.bill_status == 1 && authbutton[2].is_check == 1"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            作废
          </el-button>
          <!-- <el-button
            v-if="row.bill_status == 2"
            type="text"
            @click.native.prevent="handleChange($index, row)"
          >
            冲改
          </el-button> -->
          <el-button
            type="text"
            @click.native.prevent="handlePrint($index, row)"
          >
            打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <check ref="check" @refresh="fetchData"></check>
    <add ref="add" @refresh="fetchData"></add>
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="XS"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <sale-order-edit ref="saleOrderEdit" @refresh="fetchData"></sale-order-edit>
    <saleOrderDetail ref="saleOrderDetail"></saleOrderDetail>
    <dui-jiang ref="duiJiang" @refresh="fetchData"></dui-jiang>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getAllSelect2 } from '@/api/advanceOrder'
  import { depotSale, deleteDepot } from '@/api/depotManagement'
  import Check from './components/check'
  import Add from './components/add'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import saleOrderEdit from '@/views/project/sale/bills/saleOrder/components/edit/index.vue'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import DuiJiang from './components/duiJiang'
  import { downloadFile, postAction } from '@/api/Employee'
  import { day_n } from '@/utils/Time'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getSelect } from '@/api/saleOrder'

  export default {
    name: 'DepotSale',
    components: {
      Check,
      Add,
      RuilangDisplayDialog,
      saleOrderEdit,
      saleOrderDetail,
      DuiJiang,
      ClientSearch,
    },
    data() {
      return {
        data_id: null,
        loading: false,
        time: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        ckList: [],
        jieqingList: [
          {
            id: 0,
            name: '未结清',
          },
          {
            id: 1,
            name: '已结清',
          },
          {
            id: 2,
            name: '坏账',
          },
        ],
        totalAll: {},
        form: {
          pageNo: 1, //当前页，默认1
          pageSize: 10, //页条数，默认10
          depot_id: '', //仓库ID
          name: '', //关键词
          pay_status: '', //结算结果 0未结清 1已结清 2坏账
          start_time: '', //区间开始时间
          end_time: '', //区间结束时间
        },
        list: [],
        // 0: {id: 1, name: "待对账"}
        // 1: {id: 2, name: "已对账"}
        // 2: {id: 3, name: "已冲改"}
        // 3: {id: 4, name: "已作废"}
        zhuangtaiList: [
          {
            id: '1',
            name: '待对账',
          },
          {
            id: '2',
            name: '已对账',
          },
          {
            id: '4',
            name: '已作废',
          },
        ],
        checkList: [
          '销售单号',
          '出货仓库',
          '客户名称',
          '订单金额',
          '应收金额',
          '实收金额',
          '结算方式',
          '状态',
          '下单时间',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '销售单号',
            prop: 'bill_code',
            width: '160px',
          },
          {
            order: 2,
            label: '出货仓库',
            prop: 'depot_name',
            width: '110px',
          },
          {
            order: 3,
            label: '客户名称',
            prop: 'cust_name',
            width: '160px',
          },
          {
            order: 4,
            label: '订单金额',
            prop: 'total_amount',
            width: '',
          },
          {
            order: 5,
            label: '应收金额',
            prop: 'receiv_money',
            width: '',
          },
          {
            order: 6,
            label: '实收金额',
            prop: 'pay_amount',
            width: '',
          },
          {
            order: 7,
            label: '结算方式',
            prop: 'account_type_text',
            width: '',
          },
          {
            order: 8,
            label: '状态',
            prop: 'bill_status_text',
            width: '',
          },
          {
            order: 9,
            label: '下单时间',
            prop: 'create_at',
            width: '130px',
          },
          {
            order: 10,
            label: '备注',
            prop: 'remark',
            width: '',
          },
        ],
        authbutton: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.getTableWidth()
      this.initTime()
      getSelect().then((res) => {
        // this.areaList = res.data.cust_area //区域
        this.authbutton = res.data.fun_list
      })
      this.fetchData()
      // 仓库下拉
      getAllSelect2().then((res) => {
        this.ckList = res.data
      })
    },
    mounted() {},
    methods: {
      initTime() {
        this.time = day_n(180)
        this.form.start_time = this.time[0]
        this.form.end_time = this.time[1]
        // console.log('day_n(90)', day_n(90))
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await depotSale(this.form)
        this.list = data.rows
        this.total = totalCount
        this.totalAll = data.heji
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleCheck(index, row) {
        this.$refs.check.id = row.id
        this.$refs.check.showDialog = true
      },
      // 表格合计fun
      summaryFunction(param) {
        // const { columns, data } = param
        // 需要合计的下标
        // let i = [5, 6, 7]
        let sums = ['总计']
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => Number(item[col.property]))
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         sums[n] = a.toFixed(2) + ' 元'
        //       }
        //     }
        //   })
        //   if (idx == 0) {
        //     sums[idx] = '总计'
        //   }
        // })
        console.log('sss', sums)
        sums[5] = this.totalAll.total_amount
        sums[6] = this.totalAll.receiv_money
        sums[7] = this.totalAll.pay_amount
        return sums
      },
      handleDelete(index, row) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteDepot({ id: row.id }).then((res) => {
              this.$message.success(res.msg)
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleChange(index, row) {},
      handlePrint(index, row) {
        console.log('打印', row)
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      handleAdd() {
        this.$refs.add.showDialog = true
      },
      handleEdit(idx, row) {
        this.$refs.saleOrderEdit.isshowDialog = true
        this.$refs.saleOrderEdit.id = row.id
        this.$refs.saleOrderEdit.edit = false
      },
      handleOrderDetail(row) {
        this.$refs['saleOrderDetail'].isshowDialog = true
        this.$refs['saleOrderDetail'].id = row.id
      },
      handleAddDuijiang() {
        this.$refs.duiJiang.showDialog = true
      },
      handleExport() {
        downloadFile(
          '/depotAdmin/order/export-index',
          '仓库直销列表.xlsx',
          this.form
        )
      },
      setClientKeyword(val) {
        console.log('v', val)
        if (val.id) {
          this.form.cust_id = val.id
        } else {
          this.form.cust_id = ''
        }
        // this.form. = val
      },
      // 获取一开始表格cloumns数据
      async getTableWidth() {
        let { data } = await postAction('/setAdmin/config/form-set-list', {
          form_type: 4,
        })
        console.log('data', data)
        let checkList = []
        data.forEach((i) => {
          if (i.width == 0) {
            i.width = ''
          }
          if (!i.hidden) {
            checkList.push(i.label)
          }
        })
        if (checkList.length == 0) {
          this.checkList = [
            '销售单号',
            '出货仓库',
            '客户名称',
            '订单金额',
            '应收金额',
            '实收金额',
            '结算方式',
            '状态',
            '下单时间',
            '备注',
          ]
        } else {
          this.checkList = checkList
        }
        if (data.length == 0) {
          this.columns = [
            {
              order: 1,
              label: '销售单号',
              prop: 'bill_code',
              width: '160px',
            },
            {
              order: 2,
              label: '出货仓库',
              prop: 'depot_name',
              width: '110px',
            },
            {
              order: 3,
              label: '客户名称',
              prop: 'cust_name',
              width: '160px',
            },
            {
              order: 4,
              label: '订单金额',
              prop: 'total_amount',
              width: '',
            },
            {
              order: 5,
              label: '应收金额',
              prop: 'receiv_money',
              width: '',
            },
            {
              order: 6,
              label: '实收金额',
              prop: 'pay_amount',
              width: '',
            },
            {
              order: 7,
              label: '结算方式',
              prop: 'account_type_text',
              width: '',
            },
            {
              order: 8,
              label: '状态',
              prop: 'bill_status_text',
              width: '',
            },
            {
              order: 9,
              label: '下单时间',
              prop: 'create_at',
              width: '130px',
            },
            {
              order: 10,
              label: '备注',
              prop: 'remark',
              width: '',
            },
          ]
        } else {
          this.columns = data
        }
      },
      // 表格宽度发生变化
      async widthChange(newWidth, oldWidth, column, event) {
        console.log(newWidth, oldWidth, column, event)
        let a = this.columns.filter((i) => i.label == column.label)
        a[0].width = newWidth
        console.log('表格宽度发生变化', a)
        this.columns.forEach((i) => {
          if (i.label == column.label) {
            i.width = newWidth
          }
        })
        console.log('this.columns', this.columns)
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 4,
          form_data: JSON.stringify(this.columns),
        })
      },
      async filterChcekboxChange(v, io) {
        console.log('v', v)
        console.log('io', io)
        this.columns.forEach((i) => {
          if (i.label == io.target.value) {
            i.hidden = !v
          }
        })
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 4,
          form_data: JSON.stringify(this.columns),
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
