var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title + " " + _vm.allSelectList.dealer_name,
        visible: _vm.showDialog,
        top: "5vh",
        fullscreen: "",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                disabled: _vm.view,
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "购货方:", prop: "cust_name" } },
                [
                  _c("client-search", {
                    ref: "clientSearch",
                    staticStyle: { width: "192.6px" },
                    attrs: { "popper-class": "selectDC" },
                    on: { "select-val-alldata": _vm.clientSelect },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人:", prop: "boss" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "boss", $$v)
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _vm.allSelectList.ckzx_falg == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "业务员:", prop: "staff_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { clearable: "", placeholder: "" },
                          model: {
                            value: _vm.form.staff_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "staff_id", $$v)
                            },
                            expression: "form.staff_id",
                          },
                        },
                        _vm._l(_vm.staffList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "电话:", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址:", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "360px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "仓库:", prop: "depot_name" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "192.6px" },
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.depotSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.depot_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              !_vm.view
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品条码:", prop: "" } },
                    [_c("el-input")],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "快捷输入:" } },
                [
                  _c("el-autocomplete", {
                    staticClass: "inline-input",
                    attrs: {
                      disabled: _vm.tableEdit,
                      "value-key": "goods_name",
                      "fetch-suggestions": _vm.handlerremark,
                      placeholder: "请输入内容",
                      "trigger-on-focus": false,
                    },
                    on: { select: _vm.selectremark },
                    model: {
                      value: _vm.remark,
                      callback: function ($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: {
                    stripe: "",
                    data: _vm.list,
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "index", width: "50" },
                  }),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.label == "商品名称" ? "240" : item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var $index = ref.$index
                              var row = ref.row
                              return [
                                item.label == "类型"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { placeholder: "选择类型" },
                                            on: {
                                              change: function ($event) {
                                                _vm.utilsF(),
                                                  _vm.handleTypeChange(
                                                    $event,
                                                    row
                                                  ),
                                                  _vm.moneyN(row, $index)
                                              },
                                            },
                                            model: {
                                              value: row.goods_type,
                                              callback: function ($$v) {
                                                _vm.$set(row, "goods_type", $$v)
                                              },
                                              expression: "row.goods_type",
                                            },
                                          },
                                          _vm._l(
                                            row.arr_goods_type,
                                            function (item_) {
                                              return _c("el-option", {
                                                key: item_.id,
                                                attrs: {
                                                  label: item_.name,
                                                  value: item_.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "商品名称"
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          ref: "goodsSearch",
                                          refInFor: true,
                                          attrs: {
                                            disab: _vm.tableEdit,
                                            "cust-id": _vm.cust_id,
                                            "goods-id": row.goods_id,
                                            kouwei: row.child,
                                            rowf: row,
                                            "f-key": row.goods_name,
                                            "depot-id": _vm.form.depot_id,
                                          },
                                          on: {
                                            "change-kouwei": function ($event) {
                                              return _vm.changeKouwei(
                                                $event,
                                                row
                                              )
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.handleSelect(
                                                $index,
                                                row,
                                                $event
                                              )
                                            },
                                            "add-rows": _vm.addRows,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "规格"
                                  ? _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row.specs || "") + " "
                                      ),
                                    ])
                                  : item.label == "条码"
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currUnitCode(row)) +
                                          " "
                                      ),
                                    ])
                                  : item.label == "单位"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "70px" },
                                            attrs: { placeholder: "选择单位" },
                                            on: {
                                              change: function ($event) {
                                                _vm.unitChange($event, row),
                                                  _vm.utilsF()
                                              },
                                            },
                                            model: {
                                              value: row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_id", $$v)
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(
                                            row.arr_unit,
                                            function (item_) {
                                              return _c("el-option", {
                                                key: item_.id,
                                                attrs: {
                                                  label: item_.unit_name,
                                                  value: item_.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "单价"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          class: "danjia" + $index,
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            disabled: _vm.isEditPrice == 0,
                                            onkeyup:
                                              "value=value.replace(/[^\\-?\\d.]/g,'')",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.moneyN(row, $index),
                                                _vm.utilsF()
                                            },
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.danjiaD(
                                                $event,
                                                $index,
                                                "danjia"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.goods_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_price", $$v)
                                            },
                                            expression: "row.goods_price",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "数量"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          class: "shuliang" + $index,
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            onkeyup:
                                              "value=value.replace(/[^\\-?\\d.]/g,'')",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.moneyN(row, $index),
                                                _vm.utilsF()
                                            },
                                            focus: function ($event) {
                                              return _vm.inputfocus(row)
                                            },
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.danjiaD(
                                                $event,
                                                $index,
                                                "shuliang"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.quantity,
                                            callback: function ($$v) {
                                              _vm.$set(row, "quantity", $$v)
                                            },
                                            expression: "row.quantity",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "金额"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function ($event) {
                                              _vm.summaryInput($event, row),
                                                _vm.utilsF()
                                            },
                                          },
                                          model: {
                                            value: row.goods_money,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "goods_money",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "row.goods_money",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "备注"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: row.info,
                                            callback: function ($$v) {
                                              _vm.$set(row, "info", $$v)
                                            },
                                            expression: "row.info",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "生产日期"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "date",
                                            placeholder: "选择日期",
                                            format: "yyyy 年 MM 月 dd 日",
                                            "value-format": "yyyy-MM-dd",
                                          },
                                          model: {
                                            value: row.goods_production_date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "goods_production_date",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "row.goods_production_date",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _vm.list.length != 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "备注:", prop: "remark" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "结算方式:",
                            prop: "account_type_text",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "192.6px" },
                              attrs: { clearable: "", placeholder: "结算方式" },
                              model: {
                                value: _vm.form.account_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "account_type", $$v)
                                },
                                expression: "form.account_type",
                              },
                            },
                            _vm._l(_vm.jsfs, function (i, idx) {
                              return _c("el-option", {
                                key: idx,
                                attrs: { value: i.id, label: i.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单金额:", prop: "total_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.total_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "total_amount", $$v)
                              },
                              expression: "form.total_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "使用预存款:",
                            prop: "deposit_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deposit_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deposit_amount", $$v)
                              },
                              expression: "form.deposit_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "还货金额:", prop: "return_amount" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.return_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "return_amount", $$v)
                              },
                              expression: "form.return_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售应收:", prop: "sale_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.sale_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sale_amount", $$v)
                              },
                              expression: "form.sale_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠", prop: "discount_amount" } },
                        [
                          _c("el-input", {
                            on: { input: _vm.yhInputF },
                            model: {
                              value: _vm.form.discount_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "discount_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.discount_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "优惠后:",
                            prop: "discounted_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            on: { input: _vm.yhhInputF },
                            model: {
                              value: _vm.form.discounted_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "discounted_amount", $$v)
                              },
                              expression: "form.discounted_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退货退款", prop: "refund_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货金额",
                            prop: "refund_goods_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_goods_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_goods_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_goods_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "40%" },
                          attrs: { label: "退货方式", prop: "refund_type" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "186px" },
                              attrs: { placeholder: "退货方式" },
                              on: { change: _vm.utilsF },
                              model: {
                                value: _vm.form.refund_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "refund_type", $$v)
                                },
                                expression: "form.refund_type",
                              },
                            },
                            _vm._l(
                              _vm.allSelectList.arr_refund_type,
                              function (item_) {
                                return _c("el-option", {
                                  key: item_.id,
                                  attrs: { label: item_.name, value: item_.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售金额", prop: "sale_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.sale_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "sale_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.sale_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                      _vm.form.is_fun
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "费用抵扣", prop: "thtk" } },
                            [
                              _c("el-input", {
                                on: { input: _vm.yshandler },
                                model: {
                                  value: _vm.form.support_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "support_amount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.support_amount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_fun
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "186px" },
                                  attrs: {
                                    placeholder: "抵扣类型",
                                    multiple: "",
                                    "collapse-tags": "",
                                  },
                                  on: { change: _vm.handlerfydk },
                                  model: {
                                    value: _vm.form.fun_ids,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fun_ids", $$v)
                                    },
                                    expression: "form.fun_ids",
                                  },
                                },
                                _vm._l(_vm.funselect, function (item_) {
                                  return _c("el-option", {
                                    key: item_.id,
                                    attrs: {
                                      label:
                                        item_.funds_type +
                                        "(合计" +
                                        item_.left_amount +
                                        ")",
                                      value: item_.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "应收款:", prop: "receiv_money" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.receiv_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "receiv_money", $$v)
                              },
                              expression: "form.receiv_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.pay_info, function (item1, index) {
                        return _c(
                          "el-form-item",
                          { key: index, attrs: { label: "实收款:" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "90px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { type: "number" },
                                  model: {
                                    value: item1.money,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item1,
                                        "money",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item1.money",
                                  },
                                }),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "90px" },
                                    model: {
                                      value: item1.pay_type,
                                      callback: function ($$v) {
                                        _vm.$set(item1, "pay_type", $$v)
                                      },
                                      expression: "item1.pay_type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.allSelectList.arr_pay_type,
                                    function (item_1) {
                                      return _c("el-option", {
                                        key: item_1.id,
                                        attrs: {
                                          label: item_1.name,
                                          value: item_1.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            index == 0
                              ? _c("i", {
                                  staticClass: "el-icon-circle-plus addbtn",
                                  on: { click: _vm.addInput },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保 存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }